import { Button, Input, Modal } from "antd";
import { api_locations } from "api/admin/locations";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from "store/actionTypes";

import "../addProjectModal/AddProjectModal.css";

const EditLocation = ({ selected, oppen, setOppen, data, setData }) => {
  const [disabled, setDisabled] = useState(true);
  const [fields, setFields] = useState({
    name: "",
    description: "",
    address: "",
  });
  const [length, setLength] = useState({
    name: 0,
    description: 0,
    address: 0,
  });

  const { getLocation, editLocation } = api_locations;

  useEffect(() => {
    setFields({
      name: selected?.name,
      description: selected?.description,
      address: selected?.address,
    });
    setLength({
      name: selected?.name?.length,
      description: selected?.description?.length,
      address: selected?.address?.length,
    });
  }, [selected]);

  const onChange = (e) => {
    setLength({ ...length, [e.target.name]: e.target.value.length });
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setDisabled(
      fields.name == "" || fields.description == "" || fields.address == ""
    );
  }, [fields.name, fields.description, fields.address]);

  const submit = () => {
    let newArr = data.filter((el) => el.id != selected.id);

    editLocation({ id: selected.id, data: fields })
      .then((res) => {
        setData([...newArr, res.data]);
        setOppen(false);
        setLength({
          name: 0,
          description: 0,
          address: 0,
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal
      title="Edit location"
      open={oppen}
      onCancel={() => setOppen(false)}
      closable={false}
    >
      <button
        onClick={() => setOppen(false)}
        style={{ background: "transparent" }}
        className="customModal_closingButtonContainer"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L20 20M2 20L20 2L2 20Z"
            stroke="#fff"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          style={{ marginBottom: 20 }}
          placeholder="name"
          name="name"
          value={fields.name}
          onChange={onChange}
          maxLength={64}
        />
        <span className="input_length_counter">{length.name}/64</span>
      </div>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          style={{ marginBottom: 20 }}
          placeholder="Description"
          name="description"
          value={fields.description}
          onChange={onChange}
          maxLength={255}
        />
        <span className="input_length_counter">{length.description}/255</span>
      </div>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          style={{ marginBottom: 20 }}
          placeholder="Full address"
          name="address"
          value={fields.address}
          onChange={onChange}
          maxLength={255}
        />
        <span className="input_length_counter">{length.address}/255</span>
      </div>
      <SimpleButton
        className="simpleButton_container"
        onClick={submit}
        htmlType="submit"
        disabled={disabled}
        title={" Edit location"}
      ></SimpleButton>
    </Modal>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL }),
    setClose: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: "" }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(EditLocation);
