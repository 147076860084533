import React from "react";
import style from "./style.module.scss";
import "./media.css";
import busModel from "assets/images/testImages/busModel.png";
import { useState } from "react";
import ModelWindow from "./modelWindow/ModelWindow";

const ModelCard = ({
  data,
  setData,
  emptyTitle,
  emptyImg,
  menuItems,
  actionButtons,
  setModelRemoved,
  updatedModels,
  setDeletedId,
}) => {
  return (
    <>
      {data && data.length === 0 ? (
        <div className={style.modelCardBlock}>
          <img src={emptyImg} alt="cubeIcon" />
          <p className={`${style.modelCardBlock_text} p20`}>{emptyTitle}</p>
        </div>
      ) : (
        <>
          <ModelWindow
            setDeletedId={setDeletedId}
            updatedModels={updatedModels}
            data={data}
            setData={setData}
            modelActionButtons={actionButtons}
            img={busModel}
            setModelRemoved={setModelRemoved}
          />
        </>
      )}
    </>
  );
};

export default ModelCard;
