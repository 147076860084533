import { Button, Input, Modal } from "antd";
import { api_projects } from "api/admin/projects";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import React, { useEffect, useState } from "react";

import "../addProjectModal/AddProjectModal.css";

const EditProjectModal = ({ selected, oppen, setOppen, data, setData }) => {
  const [fields, setFields] = useState({
    name: "",
    description: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [nameLength, setNameLength] = useState(0);
  const [descriptionLength, setDescriptionLength] = useState(0);

  const { getProject, editProject } = api_projects;

  useEffect(() => {
    setFields({
      name: selected?.name,
      description: selected?.description,
    });
    setNameLength(selected?.name?.length);
    setDescriptionLength(selected?.description?.length);
  }, [selected]);

  useEffect(() => {
    setDisabled(fields.name == "" || fields.description == "");
  }, [fields.name, fields.description]);

  const submit = () => {
    editProject({ id: selected.id, data: fields })
      .then((res) => {
        const newArr = data.filter((el) => el.id != selected.id);
        setOppen(false);
        setFields([]);
        setNameLength(0);
        setDescriptionLength(0);
        setData([...newArr, res.data]);
      })
      .catch((e) => console.log(e));
  };

  const changeFields = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      title="Edit project"
      open={oppen}
      onCancel={() => setOppen(false)}
      closable={false}
    >
      <button
        onClick={() => setOppen(false)}
        style={{ background: "transparent" }}
        className="customModal_closingButtonContainer"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L20 20M2 20L20 2L2 20Z"
            stroke="#fff"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          name="name"
          style={{
            marginBottom: 20,
          }}
          placeholder="Name"
          onChange={(e) => {
            changeFields(e);
            setNameLength(e.target.value.length);
          }}
          value={fields?.name}
          maxLength={64}
        />
        <span className="input_length_counter">{nameLength}/64</span>
      </div>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          name="description"
          style={{
            marginBottom: 20,
          }}
          placeholder="Description"
          onChange={(e) => {
            changeFields(e);
            setDescriptionLength(e.target.value.length);
          }}
          value={fields?.description}
          maxLength={255}
        />
        <span className="input_length_counter">{descriptionLength}/255</span>
      </div>
      <SimpleButton
        className="simpleButton_container"
        onClick={submit}
        htmlType="submit"
        disabled={disabled}
        title={"Edit project"}
      ></SimpleButton>
    </Modal>
  );
};

export default EditProjectModal;
