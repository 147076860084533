import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import { connect } from "react-redux";
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from "store/actionTypes";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Select, Upload } from "antd";
import FileCard from "../../../ui/fileCard/FileCard";
import "./styles.scss";
import { api_statuses } from "../../../../api/admin/statuses";
import { api_statuses_history } from "../../../../api/admin/statusesHistory";
import { api_locations } from "api/admin/locations";
import { ShortString } from "utils/ShortString";

function UpdateStatus({ setVisible, setClose, modal }) {
  const [images, setImages] = useState();
  const [comment, setComment] = useState();
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState("");
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const { getLocations } = api_locations;

  const { Option } = Select;
  const allowedFormats = [
    ".jpg",
    ".jpeg",
    ".png",
    ".svg",
    ".webp",
    ".heic",
    ".dng",
    ".tiff",
    ".eps",
    ".bmp",
    ".raw",
    ".hdr",
  ];

  useEffect(() => {
    getStatusesResult().then((result) => {
      setStatuses(result.data);
    });
  }, []);

  useEffect(() => {
    getLocations()
      .then((res) => setLocations(res.data))
      .catch((e) => console.log(e));
  }, []);

  const getStatusesResult = async () => {
    return await api_statuses.getStatuses({});
  };

  const getResult = async (data) => {
    return await api_statuses_history.addStatus(data);
  };

  const request = () => {
    let data = new FormData();
    uploadFileList.forEach((el) => {
      data.append("images[]", el.originFileObj);
    });
    data.append("comment", comment);
    data.append("location_id", location);
    data.append("new_status_id", status);
    data.append("detail_id", modal.data);

    getResult(data).then((r) => setClose());
  };

  useEffect(() => {
    setDisabled(uploadFileList.length === 0 || !status || !location);
  }, [uploadFileList, status, location]);

  function getFileExtension(fileName) {
    return fileName.split(".").pop();
  }

  function checkFileFormat(fileName) {
    const fileExtension = getFileExtension(fileName).toLowerCase();
    return allowedFormats.includes(`.${fileExtension}`);
  }

  const beforeUpload = (file) => {
    return checkFileFormat(file.name) || Upload.LIST_IGNORE;
  };

  return (
    <div className={styles.container}>
      <p className="p17">Add Report</p>
      <Select
        placeholder="Choose new status"
        onChange={(id) => setStatus(id)}
      >
        {statuses.map((item, i) => {
          return (
            <Option value={item.id} key={item.id}>
              <div className={styles.option}>
                {item.name}
                <span
                  className={styles.color}
                  style={{ backgroundColor: item.color }}
                ></span>
              </div>
            </Option>
          );
        })}
      </Select>
      <Select
        placeholder="Choose Locations"
        onChange={(id) => {
          setLocation(id);
        }}
      >
        {locations.map((item, i) => {
          return (
            <Option value={item.id} key={item.id}>
              <div className={styles.option}>{ShortString(item.name, 54)}</div>
            </Option>
          );
        })}
      </Select>
      <textarea
        onChange={($event) => setComment($event.target.value)}
        className={styles.textarea}
        placeholder="Comment (optional)"
      ></textarea>
      <Upload.Dragger
        accept={allowedFormats.join(", ")}
        beforeUpload={beforeUpload}
        name="fileList"
        fileList={uploadFileList}
        onChange={(data) => {
          setUploadFileList(data.fileList);
        }}
        itemRender={(originNode, file, fileList, actions) => (
          <FileCard
            file={file}
            fileList={fileList}
            removeFile={() => {
              actions.remove();
            }}
            onlyDelete
          />
        )}
      >
        <span className="p15_600 greyText"> Drop Images here</span>
        <SimpleButton title={"Browse Images"} small></SimpleButton>
      </Upload.Dragger>
      <SimpleButton
        disabled={disabled}
        title={"Update status"}
        onClick={request}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return state;
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL }),
    setClose: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: "" }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(UpdateStatus); // hint if one of the functions is not needed replace by null
