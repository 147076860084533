import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Store } from "react-notifications-component";

import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";

import copy from "assets/images/icons/copy.svg";
import download from "assets/images/icons/downloadWhite.svg";
import close from "assets/images/icons/close.svg";

import "./QRModal.scss";

const QRModal = ({ data, open, setOppen }) => {
  const [url, setUrl] = useState("");

  const qrCodeRef = useRef(null);

  useEffect(() => {
    if (data) {
      setUrl(`https://${window.location.hostname}/user/model/${data.id}`);
    }
  }, [data]);

  const showNotification = () => {
    Store.addNotification({
      title: "Copy",
      message: "You copied the link to this model",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  };

  const downloadQRCode = () => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `QR_${data.name}.png`;
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    showNotification();
  };

  return (
    <Modal
      width={448}
      title="QR-code"
      open={open}
      onCancel={() => setOppen(false)}
      closeIcon={<img src={close} width={18} height={18} />}
    >
      <div className="qr__inner">
        <QRCode size={203} value={url || "QR-code is empty"} ref={qrCodeRef} />
      </div>
      <div className="qr__input-wrapper">
        <input
          className="qr__input"
          placeholder="Enter your url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <div className="qr__input-img__wrapper" onClick={copyUrl}>
          <img src={copy} alt="" />
        </div>
      </div>
      <button className="qr__btn" onClick={downloadQRCode}>
        <img src={download} alt="" />
        <span>Download QR-code</span>
      </button>
    </Modal>
  );
};

export default QRModal;
