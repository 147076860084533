import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import { connect } from "react-redux";
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from "store/actionTypes";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./styles.module.scss";
import { Alert, Button, Input, Modal, Popconfirm, Select, Upload } from "antd";
import FileCard from "../../../ui/fileCard/FileCard";
import { api_details } from "../../../../api/admin/details";
import { api_statuses } from "../../../../api/admin/statuses";
import { api_locations } from "api/admin/locations";
import { api_projects } from "api/admin/projects";
import { Link, NavLink } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import { ShortString } from "utils/ShortString";

function AddNewModel({ setVisible, setClose, modal }) {
  const [statuses, setStatuses] = useState([]);
  const [STL, setSTL] = useState();
  const [status, setStatus] = useState();
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [fields, setFileds] = useState({});
  const [disable, setDisable] = useState({});
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [hasProject, setHasProject] = useState(false);
  const [nameIsUnique, setNameIsUnique] = useState(null);
  const [nameIsUniqueLoading, setNameIsUniqueLoading] = useState(true);

  const defferedName = useDebounce(fields?.name, 600);

  const form = useRef(null);

  useEffect(() => {
    if (modal.data) {
      setDisable(!STL);
      return;
    }
    setDisable(
      !fields.name ||
        !fields.zone ||
        !fields.group ||
        !fields.nfc_code ||
        !STL ||
        !location ||
        !status ||
        !nameIsUnique ||
        nameIsUniqueLoading
    );
  }, [fields, STL, status, location, nameIsUnique, nameIsUniqueLoading]);

  const changeFields = (e) => {
    if (e.target.name == "name") {
      setNameIsUniqueLoading(true);
    }

    setFileds({ ...fields, [e.target.name]: e.target.value });
  };

  const { getLocations } = api_locations;
  const { getProjects } = api_projects;

  useEffect(() => {
    getProjects()
      .then((res) => setProjects(res.data))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (modal) {
      setHasProject(!!modal?.data?.project?.name);
    }
  }, [modal]);

  useEffect(() => {
    getStatusesResult().then((result) => {
      setStatuses(result.data);
    });
  }, []);

  useEffect(() => {
    getLocations()
      .then((res) => setLocations(res.data))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (modal.data) {
      setValue("name", modal.data.name);
      setValue("zone", modal.data.zone);
      setValue("group", modal.data.group);
      setValue("nfc_code", modal.data.nfc_code);
    }
  }, [modal]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      zone: "",
      group: "",
      nfc_code: "",
    },
  });
  const { Option } = Select;

  const getResult = async (data) => {
    if (modal.data) {
      return await api_details.patchDetailByID(data, modal.data.id);
    }
    return await api_details.addDetails(data);
  };

  const getStatusesResult = async () => {
    return await api_statuses.getStatuses({});
  };

  const request = (data) => {
    let dataForm = new FormData();
    dataForm.append("name", data.name);
    dataForm.append("group", data.group);
    dataForm.append("zone", data.zone);
    dataForm.append("nfc_code", data.nfc_code);
    dataForm.append("location_id", location);
    dataForm.append("detail_status_id", status);
    dataForm.append("stl", STL.file);

    if (project) {
      dataForm.append("project_id", project);
    }

    getResult(dataForm).then((r) => setClose());
  };

  const changeSTL = (STL) => {
    setSTL(STL);
  };

  const beforeUpload = (file) => {
    return file.name.indexOf(".stl") > -1 || Upload.LIST_IGNORE;
  };

  const checkIsUnique = (name) => {
    api_details
      .getDetails({ search: name })
      .then((res) => {
        if (res.data.length > 0) {
          let unique = !res?.data.find((el) => el.name === name);
          setNameIsUnique(unique);
        } else if (name != "") {
          setNameIsUnique(true);
        }
        setNameIsUniqueLoading(false);
      })
      .catch((e) => setNameIsUniqueLoading(false));
  };

  useEffect(() => {
    if (defferedName) {
      checkIsUnique(fields?.name);
    }
  }, [defferedName]);

  return (
    <form
      onSubmit={handleSubmit(request)}
      className={styles.container}
      ref={form}
    >
      <p className="p17">{modal.data ? "Update model" : "Add new model"}</p>
      <input
        {...register("name")}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className={`${styles.input} ${errors.name ? styles.error : ""}`}
        placeholder="Name"
        type="text"
        id="name"
        onChange={(e) => changeFields(e)}
      />
      <p
        className={`${styles.unique_text}`}
        style={{ display: nameIsUnique === false ? "block" : "none" }}
      >
        The entered name is already in the database
      </p>
      <input
        {...register("zone")}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className={`${styles.input} ${errors.zone ? styles.error : ""}`}
        placeholder="Zone"
        type="text"
        onChange={(e) => changeFields(e)}
      />
      <input
        {...register("group")}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className={`${styles.input} ${errors.group ? styles.error : ""}`}
        placeholder="Group"
        type="text"
        onChange={(e) => changeFields(e)}
      />
      <input
        {...register("nfc_code")}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className={`${styles.input} ${errors.nfc_code ? styles.error : ""}`}
        placeholder="NFC code"
        type="text"
        onChange={(e) => changeFields(e)}
      />

      <Select
        placeholder="Choose project"
        onChange={(id) => {
          setProject(id);
        }}
        notFoundContent={
          <div className={`${styles.flex_container}`}>
            <span style={{ color: "#000" }}>No projects found</span>
            <Button type="primary" onClick={() => setClose()}>
              <NavLink to="admin/projects">Create</NavLink>
            </Button>
          </div>
        }
      >
        <Option value={null} key={null}>
          <div className={styles.option}>No project</div>
        </Option>
        {projects.map((item, i) => {
          return (
            <Option value={item.id} key={item.id}>
              <div className={styles.option}>{ShortString(item.name, 53)}</div>
            </Option>
          );
        })}
      </Select>

      {!modal.data ? (
        <>
          <Select
            placeholder="Choose status"
            onChange={(id) => {
              setStatus(id);
            }}
          >
            {statuses.map((item, i) => {
              return (
                <Option value={item.id} key={item.id}>
                  <div className={styles.option}>
                    {item.name}
                    <span
                      className={styles.color}
                      style={{ backgroundColor: item.color }}
                    ></span>
                  </div>
                </Option>
              );
            })}
          </Select>
          <Select
            placeholder="Choose location"
            onChange={(id) => {
              setLocation(id);
            }}
          >
            {locations.map((item, i) => {
              return (
                <Option value={item.id} key={item.id}>
                  <div className={styles.option}>
                    {ShortString(item.name, 53)}
                  </div>
                </Option>
              );
            })}
          </Select>
        </>
      ) : (
        ""
      )}
      <Upload.Dragger
        accept={".stl"}
        beforeUpload={beforeUpload}
        name="fileList"
        itemRender={(originNode, file, fileList, actions) => (
          <FileCard
            file={file}
            fileList={fileList}
            removeFile={() => {
              actions.remove();
              changeSTL();
            }}
            onlyDelete
          />
        )}
        customRequest={changeSTL}
        multiple={false}
      >
        <span className="p15_600 greyText"> Drop STL file here to upload</span>
        <SimpleButton title={"Browse file"} small></SimpleButton>
      </Upload.Dragger>

      {hasProject ? (
        <Popconfirm
          title={`This model already has a project: "${modal?.data?.project?.name}". Are you sure you want to change it?`}
          okText={"Update"}
          onConfirm={handleSubmit(request)}
        >
          <SimpleButton
            type="button"
            disabled={disable}
            title={modal.data ? "Update model" : "Add model"}
          />
        </Popconfirm>
      ) : (
        <SimpleButton
          disabled={disable}
          title={modal.data ? "Update model" : "Add model"}
        />
      )}
    </form>
  );
}

function mapStateToProps(state) {
  return state;
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL }),
    setClose: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: "" }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(AddNewModel); // hint if one of the functions is not needed replace by null
