import TableCard from "components/ui/tableCard/TableCard";
import { adminProjectsTable } from "data/userTableHeader";
import { allProjectsTableWidth } from "data/userTableWidth";
import React, { useEffect, useState } from "react";

import deleteIcon from "../../../assets/images/icons/deleteIcon.svg";
import editIcon from "../../../assets/images/icons/editIcon.svg";

import { connect } from "react-redux";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
import { Button, Pagination, Popover } from "antd";
import { useSearchParams } from "react-router-dom";
import AddProjectModal from "components/ui/addProjectModal/AddProjectModal";
import { api_projects } from "api/admin/projects";
import EditProjectModal from "components/ui/editProjectModal/EditProjectModal";
import ConnectProjectModal from "../connectProjectModal/ConnectProjectModal";
import { ShortString } from "utils/ShortString";
import ProjectInfoModal from "components/ui/projectInfoModal/ProjectInfoModal";

const ProjectsMangement = () => {
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [oppenAddModal, setOppenAddModal] = useState(false);
  const [oppenEditModal, setOppenEditModal] = useState(false);
  const [oppenConnectModal, setOppenConnectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [related, setRelated] = useState([]);

  const { getProjects, removeProject } = api_projects;

  useEffect(() => {
    getProjects().then((res) => {
      setData(res.data);
    });
  }, []);

  const edit = (el) => {
    setSelectedProject(el);
    setOppenEditModal(true);
  };

  const remove = (id) => {
    const newArr = data.filter((el) => el.id != id);
    removeProject(id)
      .then((res) => {
        setData(newArr);
      })
      .catch((e) => console.log(e));
  };

  const content = (el) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          danger
          style={{ width: "100%" }}
          onClick={() => remove(el.id)}
        >
          Delete
        </Button>
      </div>
    );
  };

  const addProjectToModel = (el) => {
    setSelectedProject(el);
    setOppenConnectModal(true);
  };

  const fetchItems = (table) => {
    let row = table.find((row) => row.title === "id");
    let project = data.find((project) => project.id === row.content);
    setActiveRow(project);
  };

  const createTableData = (data) => {
    if (!data.length) {
      return [];
    }

    return data.map((el) => [
      {
        title: "id",
        type: "id",
        content: el.id,
      },
      {
        title: "Name",
        type: "text",
        content: ShortString(el.name, 64),
      },
      {
        title: "Description",
        type: "text",
        content: ShortString(el.description, 100),
      },
      {
        type: "actions",
        content: editIcon,
        action: () => edit(el),
      },
      {
        type: "actions-remove",
        content: (
          <Popover content={content(el)} title="Are you sure ?">
            <img className="pointer" src={deleteIcon} alt="actionButtons" />
          </Popover>
        ),
      },
      {
        type: "add-model",
        action: () => addProjectToModel(el),
      },
    ]);
  };

  useEffect(() => {
    const cb = (e) => {
      const className = e.target.className;

      if (
        (className.includes("customTable_body__row") ||
          className.includes("customTable_body__item")) &&
        className !== "customTable_body__row__actionBlock"
      ) {
        setOpenInfo(true);
      }
    };

    document.querySelector(".customTable_body").addEventListener("click", cb);

    return () => {
      document.removeEventListener("click", cb);
    };
  }, []);

  return (
    <>
      <TableCard
        title={"Projects"}
        actionButtonTitle={"Add project"}
        actionButton={() => setOppenAddModal(true)}
        createTableData={createTableData(data)}
        headerData={adminProjectsTable}
        width={allProjectsTableWidth}
        searchParams={searchParams}
        projectProp={data}
        activeRow={activeRow}
        fetchItems={fetchItems}
        hideId={true}
      />
      <AddProjectModal
        oppen={oppenAddModal}
        setOppen={setOppenAddModal}
        data={data}
        setData={setData}
      />
      <EditProjectModal
        data={data}
        setData={setData}
        oppen={oppenEditModal}
        setOppen={setOppenEditModal}
        selected={selectedProject}
      />
      <ConnectProjectModal
        projects={data}
        open={oppenConnectModal}
        setOpen={setOppenConnectModal}
        selected={selectedProject}
        throwRelated={setRelated}
      />
      <ProjectInfoModal
        open={openInfo}
        setOpen={setOpenInfo}
        selected={activeRow}
        related={related}
      />
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: () =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type: "editLocation" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsMangement);
