export const allModelsTableWidth = [
  "28%",
  "200px",
  "227px",
  "300px",
  "20%",
  "20%",
  "20%",
  "300px",
];

export const allAddProjectModelsTableWidth = [
  "45%",
  "30%",
  "30%",
  "45%",
  "35%",
  "20%",
  "20%",
];
export const allStatusesTableWidth = ["80%", "10%", "10%", "40px", "20px"];

export const allLocationsTableWidth = ["33%", "30%", "33%", "40px", "20px"];
export const allProjectsTableWidth = [
  "calc(50% - 100px)",
  "50%",
  "50%",
  "50px",
  "50px",
  "120px",
];
