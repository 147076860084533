import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { api_locations } from "api/admin/locations";
import styles from "./styles.module.css";
import SimpleButton from "../../../components/shared/buttons/simpleButton/SimpleButton";

import "../addProjectModal/AddProjectModal.css";

const AddLocationModal = ({ oppen, setOppen, data, setData }) => {
  const [disabled, setDisabled] = useState(true);
  const [fields, setFields] = useState({
    name: "",
    description: "",
    address: "",
  });
  const [length, setLength] = useState({
    name: 0,
    description: 0,
    address: 0,
  });

  const { addLocation } = api_locations;

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setLength({ ...length, [name]: e.target.value.length });
    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    setDisabled(
      fields.name == "" || fields.description == "" || fields.address == ""
    );
  }, [fields.name, fields.description, fields.address]);

  const submit = () => {
    addLocation(fields)
      .then((res) => {
        setData([...data, res.data]);
        setFields({});
        setOppen();
        setLength({
          name: 0,
          description: 0,
          address: 0,
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal
      title="Add location"
      open={oppen}
      onCancel={() => setOppen(false)}
      closable={false}
    >
      <button
        onClick={() => setOppen(false)}
        style={{ background: "transparent" }}
        className="customModal_closingButtonContainer"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L20 20M2 20L20 2L2 20Z"
            stroke="#fff"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          placeholder="Name"
          name="name"
          onChange={onChange}
          value={fields.name}
          maxLength={64}
        />
        <span className="input_length_counter">{length.name}/64</span>
      </div>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          placeholder="Description"
          name="description"
          onChange={onChange}
          value={fields.description}
          maxLength={255}
        />
        <span className="input_length_counter">{length.description}/255</span>
      </div>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          placeholder="Full address"
          name="address"
          onChange={onChange}
          value={fields.address}
          maxLength={255}
        />
        <span className="input_length_counter">{length.address}/255</span>
      </div>
      <SimpleButton
        className="simpleButton_container"
        onClick={submit}
        htmlType="submit"
        disabled={disabled}
        title={"Add location"}
      ></SimpleButton>
    </Modal>
  );
};

export default AddLocationModal;
