import { Button } from "antd";
import React, { useEffect, useState } from "react";

import "./CustomConfirm.scss";
import { ShortString } from "utils/ShortString";

const CustomConfirm = ({
  styles,
  open,
  setOpen,
  children,
  related,
  submit,
  nameLimit,
}) => {
  const [data, setData] = useState([]);

  const close = () => {
    setOpen(false);
  };

  const removeDublicates = (arr) => {
    let newArrIds = [];
    let newArrElements = [];

    arr.forEach((el) => {
      if (!newArrIds.includes(el.id)) {
        newArrIds.push(el.id);
        newArrElements.push(el);
      }
    });

    return newArrElements;
  };

  useEffect(() => {
    setData(removeDublicates(related));
  }, [related]);

  return (
    <div style={{ width: "100%" }}>
      {open ? (
        <div className="relative">
          <div className="messageBox" style={styles}>
            <div className="messageBox__top">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="exclamation-circle"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
                stroke="#faad14"
              >
                <path
                  fill="#faad14"
                  d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"
                ></path>
              </svg>
              <p className="messageBox__text">
                Some selected models already assigned to another project
              </p>
            </div>
            <ul className="messageBox__list">
              {data?.map((el) => {
                return (
                  <li className="messageBox__list-item">
                    {el?.name} - {ShortString(el?.related, nameLimit || 50)}
                  </li>
                );
              })}
            </ul>
            <div className="messageBox__footer">
              <Button type="dashed" onClick={close}>
                Cancel
              </Button>
              <Button type="primary" onClick={submit}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={() => setOpen(true)} style={{ display: "flex" }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default CustomConfirm;
