import React from "react";
import "./style.css";
import deleteIcon from "assets/images/icons/deleteIcon.svg";

const SimpleButton = ({
  title,
  onClick = () => {},
  small,
  del,
  cancel,
  style,
  type,
  img,
  fit,
  disabled,
}) => {
  return (
    <button
      className={`simpleButton_container 
        ${small ? "small" : fit ? " fit" : ""} 
        ${del ? "simpleButton_delContainer" : ""} 
        ${cancel ? "cancelButton" : ""}
      `}
      onClick={onClick}
      style={style}
      type={type}
      disabled={disabled}
    >
      {del ? (
        <>
          <span className="p11_600 simpleButtonDel">Убрать</span>
          <img className="simpleButton_img" src={deleteIcon} alt="deleteIcon" />
        </>
      ) : (
        <>
          <p className={`p13 simpleButton_text ${cancel ? "cancel" : ""}`}>
            {title}
          </p>
          {img && (
            <img className="simpleButton_variusImg" src={img} alt="img" />
          )}
        </>
      )}
    </button>
  );
};

export default SimpleButton;
