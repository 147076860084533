import AdminLayout from "components/layout/admin/AdminLayout";
import ModelManagement from "views/admin/modelManagement/ModelManagement";
import LocationManagement from "views/admin/locationManagement/LocationManagement";
import StatusManagement from "../../views/admin/statusManagement/StatusManagement";
import ProjectsMangement from "views/admin/projectsManagement/ProjectsManagement";
import { Navigate } from "react-router-dom";

export const adminRoutings = [
  {
    path: "/admin",
    element: <AdminLayout />,
    navigate: "/admin/my-models",
    children: [
      {
        path: "",
        element: <Navigate to="/admin/my-models" />,
      },
      {
        path: "my-models",
        index: false,
        element: <ModelManagement />,
      },
      {
        path: "statuses",
        index: false,
        element: <StatusManagement />,
      },
      {
        path: "locations",
        index: false,
        element: <LocationManagement />,
      },
      {
        path: "projects",
        index: false,
        element: <ProjectsMangement />,
      },
    ],
  },
];
