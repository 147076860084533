import { Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import ModelCard from "components/ui/modelCard/ModelCard";
import TableCard from "components/ui/tableCard/TableCard";

import { allModelsTableWidth } from "../../../data/userTableWidth";
import { SET_VISIBLE_MODAL } from "../../../store/actionTypes";
import { api_details } from "../../../api/admin/details";
import { adminModelTable } from "data/userTableHeader";
import { ConvertDate } from "utils/ConvertDate";

import styles from "./styles.module.scss";
import modelIcon from "assets/images/icons/modelIcon.svg";
import ConnectModelsToProject from "../connectModelsToProject/ConnectModelsToProject";
import { ShortString } from "utils/ShortString";

const ModelManagement = ({ setVisible, modal }) => {
  const [models, setModels] = useState([]);
  const [updatedModels, setUpdatedModels] = useState([]);
  const [activeRow, setActiveRow] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [meta, setMeta] = useState(null);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [orderType, setOrderType] = useState("");
  const [order, setOrder] = useState("");
  const [modelRemoved, setModelRemoved] = useState(false);
  const [modelUpdate, setModelUpdate] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [related, setRelated] = useState([]);
  const [oppenProjects, setOppenProjects] = useState(false);
  const [pickedProjects, setPickedProjects] = useState([]);
  const [forceUpdateModels, setForceUpdateModels] = useState(false);
  const [projectId, setProjectId] = useState();
  const [uncheckedRelated, setUncheckedRelated] = useState([]);
  const [deletedId, setDeletedId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { getDetailByID, getDetails } = api_details;

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      page,
      search,
      order_type: orderType,
      order,
    });
  }, [page, search, orderType, order]);

  const getResult = async () => {
    const query = { ...Object.fromEntries([...searchParams]) };
    let params = {
      limit: query.limit ?? limit,
      page: query.page ?? page,
      search: query.search ?? "",
    };
    if (query.order_type && query.order_type !== "null") {
      params.order_type = query.order_type ?? "";
    }
    if (params.order_type) {
      params.order = query.order;
    }
    return await api_details.getDetails(params);
  };

  useEffect(() => {
    if (modelRemoved) {
      getResult().then((result) => {
        setModels(result.data);
        setMeta(result.meta);
        setModelRemoved(false);
      });
    }
  }, [modelRemoved]);

  useEffect(() => {
    if (!modal.type) {
      getResult().then((result) => {
        setModels(result.data);
        setMeta(result.meta);
        if (activeRow?.id) {
          let updatedElement = result.data.find((el) => el.id === activeRow.id);
          setActiveRow(updatedElement);
        }
      });
    }
  }, [
    Object.fromEntries([...searchParams]).search,
    Object.fromEntries([...searchParams]).order_type,
    Object.fromEntries([...searchParams]).order,
    Object.fromEntries([...searchParams]).limit,
    Object.fromEntries([...searchParams]).page,
    modal.type,
  ]);

  const fetchItems = (table) => {
    let row = table.find((row) => row.type === "id");
    let model = models.find((model) => model.id === row.content);
    setActiveRow(model);
  };

  const createTableData = (models) => {
    if (!models.length) {
      return [];
    }

    return models.map((el) => [
      {
        type: "text",
        content: el.name,
      },
      {
        type: "text",
        content: el.zone,
      },
      {
        type: "text",
        content: el.group,
      },
      {
        type: "status",
        content: el.detail_status?.name,
        color: el.detail_status?.color,
      },
      {
        type: "text",
        content: el?.project?.name || "No project",
      },
      {
        type: "text",
        content: ConvertDate(el.created_at),
      },
      {
        type: "id",
        content: el.id,
      },
      {
        type: "text",
        content: el.nfc_code,
      },
    ]);
  };

  useEffect(() => {
    updatedModels.forEach((model) => {
      if (model?.id == activeRow?.id) {
        setActiveRow(model);
      }
    });
  }, [models]);

  const checkRelatedModels = () => {
    setConfirmLoading(true);
    checkedIds.forEach((id) => {
      getDetailByID(id)
        .then((res) => {
          setConfirmLoading(false);
          if (
            res.data?.project?.name &&
            res.data?.project?.id != projectId &&
            !!related.find((el) => el?.id == id) === false
          ) {
            setRelated((prev) => [
              ...prev,
              {
                id: res.data?.id,
                name: res.data?.name,
                relatedId: res.data?.project?.id,
                related: res.data?.project?.name,
              },
            ]);
          }
        })
        .catch((e) => {
          setConfirmLoading(false);
        });
    });
  };

  useEffect(() => {
    console.log(related);
  }, [related]);

  useEffect(() => {
    if (!!related.find((el) => el.relatedId == projectId) === true) {
      setRelated(related.filter((el) => el.relatedId != projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (deletedId) {
      let removeDeletedModel = checkedIds.filter((id) => id != deletedId);
      setCheckedIds(removeDeletedModel);
    }
  }, [deletedId]);

  useEffect(() => {
    checkRelatedModels();
  }, [checkedIds, projectId]);

  useEffect(() => {
    uncheckedRelated.forEach((id) => {
      let relatedFilter = related.filter((el) => el?.id != id);
      setRelated(relatedFilter);
    });
  }, [uncheckedRelated]);

  return (
    <div className={styles.container}>
      <TableCard
        pageSize={50}
        title={"Models"}
        actionButtonTitle={"Add model"}
        actionButton={setVisible}
        createTableData={createTableData(models)}
        headerData={adminModelTable}
        width={allModelsTableWidth}
        search={true}
        fetchItems={fetchItems}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        meta={meta}
        page={page}
        setPage={setPage}
        searchData={search}
        setSearch={setSearch}
        setOrderType={setOrderType}
        setOrder={setOrder}
        orderType={orderType}
        order={order}
        activeRow={activeRow}
        models={models}
        setModels={setModels}
        enableCheckBoxes={true}
        setCheckedIds={setCheckedIds}
        checkedIds={checkedIds}
        forceUpdateModels={forceUpdateModels}
        uncheckedRelated={uncheckedRelated}
        setUncheckedRelated={setUncheckedRelated}
        actionButtonElement={
          checkedIds.length > 0 ? (
            <button
              className={`${styles.action_btn}`}
              onClick={() => setOppenProjects(true)}
            >
              Assign to project
            </button>
          ) : null
        }
      />
      <ModelCard
        setDeletedId={setDeletedId}
        updatedModels={updatedModels}
        models={models}
        data={activeRow}
        setData={setActiveRow}
        emptyTitle={`select 3D-model`}
        emptyImg={modelIcon}
        setModelRemoved={setModelRemoved}
      />
      <ConnectModelsToProject
        loading={confirmLoading}
        related={related}
        setRelated={setRelated}
        setProjectId={setProjectId}
        forceUpdateModels={forceUpdateModels}
        setForceUpdateModels={setForceUpdateModels}
        updatedModels={updatedModels}
        setUpdatedModels={setUpdatedModels}
        setOppen={setOppenProjects}
        open={oppenProjects}
        checkedIds={checkedIds}
        setCheckedIds={setCheckedIds}
        activeRow={activeRow}
        setModels={setModels}
        models={models}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: () =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type: "addNewModel" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelManagement);
