import ActionButton from "components/shared/buttons/actionButton/ActionButton";
import React, { useCallback, useEffect, useState } from "react";
import Table from "../table/Table";
import style from "./style.module.scss";
import "./media.css";
import { Input, Pagination } from "antd";
import DropDown from "../dropDown/DropDown";
import { api_statuses } from "api/admin/statuses";
import { api_details } from "api/admin/details";
import useDebounce from "hooks/useDebounce";
import { api_projects } from "api/admin/projects";
import { ShortString } from "utils/ShortString";

const TableCard = ({
  title,
  titleStyles,
  fetchItems,
  actionButtonElement,
  actionButtonTitle,
  actionButton,
  createTableData,
  headerData,
  activeRow,
  width,
  search,
  searchParams,
  setSearchParams,
  meta,
  PropPage,
  setPage,
  pageSize,
  setSearch,
  searchData,
  setOrderType,
  setOrder,
  orderType,
  order,
  models,
  setModels,
  setSort,
  hidePagination,
  enableCheckBoxes,
  checkedIds,
  setCheckedIds,
  uncheckedIds,
  setUnCheckedIds,
  initialCheckedIds,
  throwFilters,
  throwPickedStatuses,
  throwPickedProject,
  forceUpdateModels,
  uncheckedRelated,
  setUncheckedRelated,
  projectProp,
  setMeta,
  hideId,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { Search } = Input;
  const [searchString, setSearchString] = useState(searchData);
  const [statuses, setStatuses] = useState([]);
  const [oppenFilter, setOpenFilter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [projects, setProjects] = useState([]);
  const [pickedProjects, setPickedProjects] = useState([]);

  const [metaData, setMetaData] = useState(null);

  const { getProjects } = api_projects;

  const [filters, setFilters] = useState({
    group: "",
    zone: "",
    status: "",
    search: "",
    sort: "",
    orderType: "",
  });

  const [pickedStatuses, setPickedStatuses] = useState([]);

  const defferedGroup = useDebounce(filters.group, 500);
  const defferedZone = useDebounce(filters.zone, 500);
  const defferedSearch = useDebounce(filters.search, 800);

  useEffect(() => {
    if (metaData && setMeta) {
      setMeta(metaData);
    }
  }, [metaData]);

  useEffect(() => {
    getStatusesList();
  }, []);

  useEffect(() => {
    if (projectProp) {
      setProjects(projectProp);
    }
  }, [projectProp]);

  useEffect(() => {
    getProjects()
      .then((res) => setProjects(res.data))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (throwFilters) {
      throwFilters(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (throwPickedProject) {
      throwPickedProject(pickedProjects);
    }
  }, [pickedProjects]);

  useEffect(() => {
    if (throwPickedStatuses) {
      throwPickedStatuses(pickedStatuses);
    }
  }, [pickedStatuses]);

  function onSearch(value) {
    setSearch(value);
  }

  useEffect(() => {
    const closeFilters = (e) => {
      if (e.key === "Escape") {
        setOpenFilter(-1);
      }
    };

    window.addEventListener("keydown", closeFilters);

    return () => window.removeEventListener("keydown", closeFilters);
  }, []);

  const getStatusesList = () => {
    api_statuses
      .getStatuses("")
      .then((res) => setStatuses([...res.data]))
      .catch((e) => console.log(e));
  };

  const updateModels = (page) => {
    api_details
      .searchDetails({
        group: filters.group.toUpperCase(),
        zone: filters.zone,
        search: filters.search,
        sort: filters.sort,
        order_type: filters.orderType,
        status: pickedStatuses.toString().replace(",", ", "),
        project: pickedProjects.toString().replace(",", ", "),
        page: PropPage || page || currentPage,
        limit: pageSize || "",
      })
      .then((res) => {
        setModels([...res.data]);
        setMetaData(res.meta);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    updateModels(1);

    if (title === "Status") {
      setSort(filters.orderType);
    }
  }, [
    defferedGroup,
    defferedZone,
    defferedSearch,
    pickedStatuses,
    pickedProjects,
    filters.sort,
    filters.orderType,
    forceUpdateModels,
    PropPage,
  ]);

  useEffect(() => {
    updateModels(currentPage);
  }, [currentPage]);

  const setCurrentFilterId = (e) => {
    const target = e.target;
    const tag = target.tagName;
    const parent = target.parentNode;

    if (tag === "BUTTON") {
      setOpenFilter(target.id);
    } else if (tag === "svg") {
      setOpenFilter(parent.id);
    } else if (tag === "path") {
      setOpenFilter(parent.parentNode.id);
    }
  };

  const onPickFilter = (id, state, setState, entity) => {
    if (state.length > 0) {
      state.forEach((el) => {
        if (!state.find((el) => el === id)) {
          setState([...state, id]);
        } else if (el == id) {
          setState(state?.filter((el) => el != id));
        }
      });
    } else {
      setState([...state, id]);
    }

    document.getElementById(`${id}_${entity}`).classList.toggle("item_active");
  };

  const resetPickedFilters = (state, setState, entity) => {
    setState([]);

    state.forEach((id) => {
      document
        .getElementById(`${id}_${entity}`)
        .classList.remove("item_active");
    });
  };

  return (
    <div
      className={style.tableCard_container}
      style={title == "Models" ? { maxWidth: "70%", width: "100%" } : null}
    >
      <div className={style.topHolder}>
        <div className="p24" style={titleStyles}>
          {title}
        </div>

        {search ? (
          <>
            <div
              className={`${style.filters}`}
              onClick={(e) => setCurrentFilterId(e)}
            >
              <DropDown
                className={`${filters.zone ? style.dropdown : ""}`}
                contentWidth={"170px"}
                btnText={"By zone"}
                id={"drop_0"}
                currentId={oppenFilter}
              >
                <div className={`${style.dropdown_inner}`}>
                  <p className={`${style.dropdown_text}`}>
                    Enter the zone numbers separated by commas
                  </p>
                  <input
                    className={`${style.dropdown_input}`}
                    type="text"
                    placeholder="№"
                    onChange={(e) =>
                      setFilters({ ...filters, zone: e.target.value })
                    }
                  />
                </div>
              </DropDown>
              <DropDown
                className={`${filters.group ? style.dropdown : ""}`}
                contentWidth={"170px"}
                btnText={"By group"}
                id={"drop_1"}
                currentId={oppenFilter}
              >
                <div className={`${style.dropdown_inner}`}>
                  <p className={`${style.dropdown_text}`}>
                    Enter the group numbers separated by commas
                  </p>
                  <input
                    className={`${style.dropdown_input}`}
                    type="text"
                    placeholder="№"
                    onChange={(e) =>
                      setFilters({ ...filters, group: e.target.value })
                    }
                  />
                </div>
              </DropDown>
              <DropDown
                className={`${pickedStatuses.length > 0 ? style.dropdown : ""}`}
                contentWidth={"130px"}
                btnText={"By status"}
                id={"drop_2"}
                currentId={oppenFilter}
              >
                <ul className={`${style.statuses_list}`}>
                  <li className={`${style.statuses_list_item}`}>
                    <button
                      className={`${style.statuses_btn}`}
                      onClick={() =>
                        resetPickedFilters(
                          pickedStatuses,
                          setPickedStatuses,
                          "statuses"
                        )
                      }
                    >
                      All
                    </button>
                  </li>
                  {statuses.map((status) => (
                    <li
                      className={`${style.statuses_list_item}`}
                      id={status.id + "_statuses"}
                      key={status.id}
                    >
                      <button
                        className={`${style.statuses_btn}`}
                        onClick={() =>
                          onPickFilter(
                            status.id,
                            pickedStatuses,
                            setPickedStatuses,
                            "statuses"
                          )
                        }
                      >
                        {status.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </DropDown>
              <DropDown
                className={`${pickedProjects.length > 0 ? style.dropdown : ""}`}
                contentWidth={"130px"}
                btnText={"By project"}
                id={"drop_3"}
                currentId={oppenFilter}
              >
                <ul className={`${style.statuses_list}`}>
                  <li className={`${style.statuses_list_item}`}>
                    <button
                      className={`${style.statuses_btn}`}
                      onClick={() =>
                        resetPickedFilters(
                          pickedProjects,
                          setPickedProjects,
                          "projects"
                        )
                      }
                    >
                      All
                    </button>
                  </li>
                  {projects.map((project) => (
                    <li
                      className={`${style.statuses_list_item}`}
                      id={project.id + "_projects"}
                      key={project.id}
                    >
                      <button
                        className={`${style.statuses_btn}`}
                        onClick={() =>
                          onPickFilter(
                            project.id,
                            pickedProjects,
                            setPickedProjects,
                            "projects"
                          )
                        }
                      >
                        {ShortString(project.name, 13)}
                      </button>
                    </li>
                  ))}
                </ul>
              </DropDown>
            </div>
            <Search
              placeholder={"Find by  Name, ID, NFC"}
              onSearch={onSearch}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
          </>
        ) : (
          ""
        )}
        {actionButtonElement || (
          <ActionButton
            className={style.action}
            title={actionButtonTitle}
            onClick={actionButton}
          />
        )}
      </div>
      <Table
        title={title}
        header={headerData}
        width={width}
        data={createTableData}
        fetchItems={fetchItems}
        searchTerm={searchTerm}
        activeRow={activeRow}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setOrderType={setOrderType}
        setOrder={setOrder}
        orderType={orderType}
        order={order}
        filters={filters}
        setFilters={setFilters}
        enableCheckBoxes={enableCheckBoxes}
        setCheckedIds={setCheckedIds}
        checkedIds={checkedIds}
        uncheckedIds={uncheckedIds}
        initialCheckedIds={initialCheckedIds}
        setUnCheckedIds={setUnCheckedIds}
        setUncheckedRelated={setUncheckedRelated}
        uncheckedRelated={uncheckedRelated}
        hideId={hideId}
      />
      {!hidePagination ? (
        <Pagination
          align="start"
          defaultCurrent={1}
          total={metaData?.total}
          pageSize={50}
          defaultPageSize={50}
          showSizeChanger={false}
          showQuickJumper={false}
          onChange={(number) => {
            setCurrentPage(number);
          }}
        />
      ) : null}
    </div>
  );
};
export default TableCard;
