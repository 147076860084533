import { connect } from "react-redux";
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from "store/actionTypes";
import React from "react";
import styles from "./styles.module.scss";
import "./styles.scss";
import Characteristics from "../../../ui/characteristics/Characteristics";
import { div } from "three/nodes";
import noModel from "../../../../assets/images/icons/noModel.svg";
import { ShortString } from "utils/ShortString";

const contentStyle = {
  margin: 0,
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
};

function ReviewView({ setVisible, setClose, modal }) {
  const createCharacteristics = (data) => {
    const dateObject = new Date(data?.updated_at);
    return data?.updated_at
      ? [
          {
            title: "Date",
            value: `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${dateObject
              .getDate()
              .toString()
              .padStart(2, "0")} ${dateObject
              .getHours()
              .toString()
              .padStart(2, "0")}:${dateObject
              .getMinutes()
              .toString()
              .padStart(2, "0")}`,
          },
          {
            title: "Location",
            value: ShortString(data?.location?.name, 70) || "No location",
          },
          {
            title: "Old status",
            value: data?.previous_status?.name,
            color: data?.previous_status?.color,
          },
          {
            title: "New Status",
            value: data?.new_status?.name,
            color: data?.new_status?.color,
          },
        ]
      : [];
  };

  return (
    <>
      <p className="p17 mb-33">Report view</p>
      <div className={styles.container}>
        <div className={styles.content} style={{ width: "50%" }}>
          <Characteristics data={createCharacteristics(modal.data)} />
          <textarea
            disabled={true}
            className="textarea textarea-review"
            placeholder="No comment"
            value={
              modal.data.comment == "undefined"
                ? "No comment"
                : modal.data.comment
            }
          ></textarea>
        </div>
        <div
          className={styles.content}
          style={{ width: "50%", maxHeight: "500px", overflowY: "auto" }}
        >
          {modal.data.images.map((el) => (
            <img
              style={contentStyle}
              className="modelWindow_img"
              src={el.path}
              alt="model"
            />
          ))}
          {!modal.data.images?.length || !modal.data.images[0]?.path ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img className="modelWindow_img" src={noModel} alt="model" />
              <p className="noHistory">No photo</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL }),
    setClose: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: "" }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(ReviewView); // hint if one of the functions is not needed replace by null
