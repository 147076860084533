import EditLocation from "components/ui/editLocation/EditLocation";
import { ApiUrl, instance } from "../apiEnv";

export const api_projects = {
  async getProject(id) {
    let result = await instance.get(`${ApiUrl}/projects/${id}`);

    return result.data;
  },

  async getProjects(search = "") {
    let result = await instance.get(`${ApiUrl}/projects?search=${search}`);

    return result.data;
  },

  async addProject(data) {
    let result = await instance.post(`${ApiUrl}/projects`, data);

    return result.data;
  },

  async editProject({ data, id }) {
    let result = await instance.put(`${ApiUrl}/projects/${id}`, data);

    return result.data;
  },

  async removeProject(id) {
    let result = await instance.delete(`${ApiUrl}/projects/${id}`);

    return result.data;
  },
};
