import { Button, Input, Modal } from "antd";
import { api_projects } from "api/admin/projects";
import React, { useEffect, useState } from "react";

import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";

import "./AddProjectModal.css";

const AddProjectModal = ({ oppen, setOppen, data, setData }) => {
  const [disabled, setDisabled] = useState(true);
  const [fields, setFields] = useState({
    name: "",
    description: "",
  });
  const [nameLength, setNameLength] = useState(0);
  const [descriptionLength, setDescriptionLength] = useState(0);

  const { addProject } = api_projects;

  const changeFields = (e) => {
    if (!e) return;

    let name = e.target.name;
    let value = e.target.value;

    setFields({
      ...fields,
      [name]: value,
    });
  };

  useEffect(() => {
    setDisabled(fields.name === "" || fields.description === "");
  }, [fields.name, fields.description]);

  const submit = () => {
    addProject(fields)
      .then((res) => {
        setData([...data, res.data]);
        setFields({});
        setOppen(false);
        setNameLength(0);
        setDescriptionLength(0);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal
      title="Add project"
      open={oppen}
      onCancel={() => setOppen(false)}
      closable={false}
    >
      <button
        onClick={() => setOppen(false)}
        style={{ background: "transparent" }}
        className="customModal_closingButtonContainer"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L20 20M2 20L20 2L2 20Z"
            stroke="#fff"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          placeholder="Name"
          name="name"
          style={{
            marginBottom: 20,
          }}
          onChange={(e) => {
            changeFields(e);
            setNameLength(e.target.value.length);
          }}
          value={fields?.name}
          maxLength={64}
        />

        <span className="input_length_counter">{nameLength}/64</span>
      </div>

      <div className="counter_wrapper">
        <Input
          className="default_input_style"
          name="description"
          style={{
            marginBottom: 20,
          }}
          placeholder="Description"
          onChange={(e) => {
            changeFields(e);
            setDescriptionLength(e.target.value.length);
          }}
          value={fields?.description}
          maxLength={255}
        />
        <span className="input_length_counter">{descriptionLength}/255</span>
      </div>
      <SimpleButton
        className="simpleButton_container"
        onClick={submit}
        htmlType="submit"
        disabled={disabled}
        title={"Add project"}
      ></SimpleButton>
    </Modal>
  );
};

export default AddProjectModal;
