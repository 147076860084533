import { Divider, Modal, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";

import "./ProjectInfoModal.css";
import { api_details } from "api/admin/details";

const ProjectInfoModal = ({ open, setOpen, selected, related }) => {
  const { getDetails } = api_details;
  const [modelsAmount, setModelsAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selected) {
      setLoading(true);
      getDetails({ project: selected.id })
        .then((res) => {
          setLoading(false);
          setModelsAmount(res.data?.length);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [selected, related]);

  const asyncValue = (syncValue) => {
    if (loading) return <Spin />;

    return syncValue;
  };

  return (
    <Modal
      title="Project info"
      open={open}
      onCancel={() => setOpen(false)}
      closable={false}
    >
      <Divider />
      <button
        onClick={() => setOpen(false)}
        style={{ background: "transparent", zIndex: 99999999 }}
        className="customModal_closingButtonContainer"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L20 20M2 20L20 2L2 20Z"
            stroke="#fff"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div className="wrapper">
        <div className="item">
          <p className="item_key">Name:</p>
          <p className="item_value">{selected?.name}</p>
        </div>
        <Divider />
        <div className="item">
          <p className="item_key">Description:</p>
          <p className="item_value">{selected?.description}</p>
        </div>
        <Divider />
        <div className="item">
          <p className="item_key">Related models:</p>
          <p className="item_value">{asyncValue(modelsAmount)}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectInfoModal;
