import { Modal, Pagination, Popconfirm, Popover } from "antd";
import TableCard from "components/ui/tableCard/TableCard";
import { adminModelTable, projectsModelTable } from "data/userTableHeader";
import {
  allAddProjectModelsTableWidth,
  allProjectsTableWidth,
} from "data/userTableWidth";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Store } from "react-notifications-component";

import { api_details } from "api/admin/details";
import { ConvertDate } from "utils/ConvertDate";

import close from "../../../assets/images/icons/close.svg";
import "./ConnectProjectModal.css";
import CustomConfirm from "components/ui/customConfirm/CustomConfirm";

const ConnectProjectModal = ({
  open,
  setOpen,
  selected,
  projects,
  throwRelated,
}) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [data, setData] = useState([]);
  const [forceUpdateModels, setFroceUpdateModels] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(6);
  const [pickedStatuses, setPickedStatuses] = useState([]);
  const [pickedProjects, setPickedProjects] = useState([]);
  const [orderType, setOrderType] = useState("asc");
  const [order, setOrder] = useState("");

  const [initialCheckedIds, setInitialCheckedIds] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [uncheckedIds, setUnCheckedIds] = useState([]);
  const [uncheckedRelated, setUncheckedRelated] = useState([]);
  const [related, setRelated] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [meta, setMeta] = useState(null);
  const [error, setError] = useState(null);

  const [disabled, setDisabled] = useState(true);

  const { getDetails, patchDetailByID, getDetailByID } = api_details;

  useEffect(() => {
    setDisabled(checkedIds.length == 0 && uncheckedIds.length == 0);
  }, [checkedIds, uncheckedIds]);

  const checkRelatedProjects = () => {
    data?.forEach((el) => {
      if (
        el?.project_id == selected?.id &&
        el?.project_id != null &&
        selected?.id != undefined
      ) {
        if (!!checkedIds.find((id) => id == el?.id) === false) {
          setCheckedIds((prev) => [...prev, el?.id]);
          setInitialCheckedIds((prev) => [...prev, el?.id]);
        }
      } else if (el?.project_id == null) {
        checkedIds.forEach((checkedID) => {
          if (checkedID == el?.id) {
            let newArr = initialCheckedIds.filter((id) => id != el?.id);
            setCheckedIds(newArr);
          }
        });
      }
    });
  };

  const checkRelatedModels = () => {
    checkedIds.forEach((id) => {
      getDetailByID(id)
        .then((res) => {
          if (
            res.data?.project?.name &&
            res.data?.project?.id != selected.id &&
            !!related.find((el) => el?.id == id) === false
          ) {
            setRelated((prev) => [
              ...prev,
              {
                id: res.data?.id,
                name: res.data?.name,
                related: res.data?.project?.name,
              },
            ]);
          }
        })
        .catch((e) => console.log(e));
    });
  };

  useEffect(() => {
    uncheckedRelated.forEach((id) => {
      let relatedFilter = related.filter((el) => el?.id != id);
      setRelated(relatedFilter);
    });
  }, [uncheckedRelated]);

  useEffect(() => {
    if (related.length == 0) {
      setOpenConfirm(false);
    }

    if (related && throwRelated) {
      throwRelated(related);
    }
  }, [related]);

  useEffect(() => {
    checkRelatedModels();
  }, [checkedIds]);

  useEffect(() => {
    checkRelatedProjects();
  }, [data, pickedProjects]);

  useEffect(() => {
    if (open) {
      checkRelatedProjects();
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setInitialCheckedIds([]);
      setCheckedIds([]);
      setUnCheckedIds([]);
      setRelated([]);
    }
  }, [open]);

  useEffect(() => {
    setCheckedIds([]);
  }, []);

  const createTableData = (models) => {
    if (!models.length) {
      return [];
    }

    return models.map((el) => [
      {
        type: "text",
        content: el.name,
      },
      {
        type: "text",
        content: el.zone,
      },
      {
        type: "text",
        content: el.group,
      },
      {
        type: "status",
        content: el.detail_status?.name,
        color: el.detail_status?.color,
      },
      {
        type: "text",
        content: el?.project?.name || "No project",
      },
      {
        type: "id",
        content: el?.id,
      },
      {
        type: "text",
        content: el?.nfc_code,
      },
    ]);
  };

  const showNotification = (type, message) => {
    Store.addNotification({
      title: "Success",
      message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  };

  const onSetModelToProject = () => {
    checkedIds.forEach((id) => {
      patchDetailByID({ project_id: selected.id }, id)
        .then((res) => {
          setFroceUpdateModels(!forceUpdateModels);
        })
        .catch((e) => setError(e));
    });
    uncheckedIds.forEach((id) => {
      patchDetailByID({ project_id: null }, id)
        .then((res) => {
          setFroceUpdateModels(!forceUpdateModels);
        })
        .catch((e) => setError(e));
    });

    if (!error) {
      showNotification("success", "Changed applied successfully");
    } else {
      showNotification("warning", "An error has occurred");
    }

    setError(null);
    setOpen(false);
    setRelated([]);
  };

  return (
    <Modal
      closeIcon={<img width={18} height={18} src={close} />}
      title={"Select a models"}
      width={"61%"}
      open={open}
      onCancel={() => setOpen(false)}
      closable={false}
    >
      <button
        onClick={() => setOpen(false)}
        style={{ background: "transparent" }}
        className="customModal_closingButtonContainer"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L20 20M2 20L20 2L2 20Z"
            stroke="#fff"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <TableCard
        setMeta={setMeta}
        projectProp={projects}
        setModels={setData}
        pageSize={10}
        titleStyles={{ display: "none" }}
        actionButtonTitle={"Add model(s)"}
        actionButtonElement={
          related.length > 0 ? (
            <CustomConfirm
              related={related}
              open={openConfirm}
              setOpen={setOpenConfirm}
              submit={onSetModelToProject}
            >
              <button className="action-btn" disabled={disabled}>
                Save changes
              </button>
            </CustomConfirm>
          ) : (
            <button
              className="action-btn"
              disabled={disabled}
              onClick={onSetModelToProject}
            >
              Save changes
            </button>
          )
        }
        createTableData={createTableData(data)}
        headerData={projectsModelTable}
        width={allAddProjectModelsTableWidth}
        search={true}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        meta={meta}
        PropPage={currentPage}
        hidePagination={true}
        enableCheckBoxes={true}
        checkedIds={checkedIds}
        setCheckedIds={setCheckedIds}
        uncheckedIds={uncheckedIds}
        setUnCheckedIds={setUnCheckedIds}
        throwFilters={setFilters}
        throwPickedStatuses={setPickedStatuses}
        setOrderType={setOrderType}
        setOrder={setOrder}
        order={order}
        orderType={orderType}
        initialCheckedIds={initialCheckedIds}
        throwPickedProject={setPickedProjects}
        setUncheckedRelated={setUncheckedRelated}
        uncheckedRelated={uncheckedRelated}
        forceUpdateModels={forceUpdateModels}
      />
      <Pagination
        current={currentPage}
        align="start"
        defaultCurrent={1}
        total={meta?.total}
        pageSize={10}
        defaultPageSize={10}
        showSizeChanger={false}
        showQuickJumper={false}
        onChange={(number) => {
          setCurrentPage(number);
        }}
      />
    </Modal>
  );
};

export default ConnectProjectModal;
