import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import TableCard from "components/ui/tableCard/TableCard";

import { allLocationsTableWidth } from "../../../data/userTableWidth";
import { SET_VISIBLE_MODAL } from "../../../store/actionTypes";
import { adminLocationsTable } from "data/userTableHeader";
import { Button, Form, Input, Modal, Popover } from "antd";

import { api_locations } from "api/admin/locations";

import deleteIcon from "../../../assets/images/icons/deleteIcon.svg";
import editIcon from "../../../assets/images/icons/editIcon.svg";
import EditLocation from "components/ui/editLocation/EditLocation";
import AddLocationModal from "components/ui/addLocationModal/AddLocationModal";
import { ShortString } from "utils/ShortString";

const LocationManegement = () => {
  const [data, setData] = useState([]);
  const [activeRow, setActiveRow] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [orderType, setOrderType] = useState("");
  const [order, setOrder] = useState("");

  const [editOppen, setEditOppen] = useState(false);
  const [addLocationOppen, setAddLocationOppen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { getLocations, removeLocation } = api_locations;

  const getData = () => {
    getLocations()
      .then((res) => setData(res.data))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getData();
  }, []);

  const action = (el) => {
    setSelectedLocation(el);
    setEditOppen(true);
  };

  const remove = (id) => {
    let newArr = data.filter((el) => el.id != id);

    removeLocation(id + "").then((res) => {
      setData(newArr);
    });
  };

  const content = (el) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          danger
          style={{ width: "100%" }}
          onClick={() => remove(el.id)}
        >
          Delete
        </Button>
      </div>
    );
  };

  const createTableData = (data) => {
    if (!data.length) {
      return [];
    }

    return data.map((el) => [
      {
        title: "Name",
        type: "text",
        content: ShortString(el.name, 64),
      },
      {
        title: "Description",
        type: "text",
        content: ShortString(el.description, 100),
      },
      {
        title: "Address",
        type: "text",
        content: ShortString(el.address, 100),
      },
      {
        type: "actions",
        content: editIcon,
        action: () => action(el),
      },
      {
        type: "actions-remove",
        content: (
          <Popover content={content(el)} title="Are you sure ?">
            <img className="pointer" src={deleteIcon} alt="actionButtons" />
          </Popover>
        ),
      },
    ]);
  };

  return (
    <>
      <TableCard
        title={"Locations"}
        actionButtonTitle={"Add location"}
        actionButton={() => setAddLocationOppen(true)}
        createTableData={createTableData(data)}
        headerData={adminLocationsTable}
        width={allLocationsTableWidth}
        meta={meta}
        page={page}
        setPage={setPage}
        searchParams={searchParams}
        searchData={search}
        setSearch={setSearch}
        setOrderType={setOrderType}
        setOrder={setOrder}
        orderType={orderType}
        order={order}
        activeRow={activeRow}
      />
      <AddLocationModal
        data={data}
        setData={setData}
        oppen={addLocationOppen}
        setOppen={setAddLocationOppen}
      />
      <EditLocation
        selected={selectedLocation}
        data={data}
        setData={setData}
        oppen={editOppen}
        setOppen={setEditOppen}
      />
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: () =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type: "editLocation" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationManegement);
