export const adminStatusTable = [
  {
    title: "Name",
    sort: false,
  },
  {
    title: "Sort",
    sort: true,
  },
  {
    title: "Color",
    sort: false,
  },
  {
    title: "",
    sort: false,
  },
  {
    title: "",
    sort: false,
  },
];

export const adminModelTable = [
  {
    title: "Name",
    sort: true,
    sort_param: "name",
  },
  {
    title: "Zone",
    sort: true,
    sort_param: "zone",
  },
  {
    title: "Group",
    sort: true,
    sort_param: "group",
  },
  {
    title: "Status",
    sort: true,
    sort_param: "detail_status_id",
  },
  {
    title: "Project",
    sort: true,
    sort_param: "project",
  },
  {
    title: "Date",
    sort: true,
    sort_param: "created_at",
  },
  {
    title: "ID",
    sort: true,
    sort_param: "id",
  },
  {
    title: "NFC tag",
    sort: true,
    sort_param: "nfc",
  },
];

export const projectsModelTable = [
  {
    title: "Name",
    sort: true,
    sort_param: "name",
  },
  {
    title: "Zone",
    sort: true,
    sort_param: "zone",
  },
  {
    title: "Group",
    sort: true,
    sort_param: "group",
  },
  {
    title: "Status",
    sort: true,
    sort_param: "detail_status_id",
  },
  {
    title: "Project",
    sort: true,
    sort_param: "project",
  },
  {
    title: "ID",
    sort: true,
    sort_param: "id",
  },
  {
    title: "NFC tag",
    sort: true,
    sort_param: "nfc",
  },
];

export const adminLocationsTable = [
  {
    title: "Name",
    sort: false,
  },
  {
    title: "Description",
    sort: false,
  },
  {
    title: "Full address",
    sort: false,
  },
];

export const adminProjectsTable = [
  {
    title: "Name",
    sort: false,
  },
  {
    title: "Description",
    sort: false,
  },
];
