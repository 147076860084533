import { Button, Input, List, Modal, Popconfirm, Radio, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { api_projects } from "api/admin/projects";
import { Store } from "react-notifications-component";

import check from "../../../assets/images/icons/check.svg";
import "./ConnectModelsToProject.scss";
import { api_details } from "api/admin/details";
import useDebounce from "hooks/useDebounce";
import { NavLink } from "react-router-dom";
import CustomConfirm from "components/ui/customConfirm/CustomConfirm";
import { ShortString } from "utils/ShortString";

const ConnectModelsToProject = ({
  setOppen,
  open,
  checkedIds,
  setCheckedIds,
  activeRow,
  models,
  setModels,
  updatedModels,
  setUpdatedModels,
  forceUpdateModels,
  setForceUpdateModels,
  setProjectId,
  related,
  setRelated,
  loading,
}) => {
  const { getProjects } = api_projects;
  const { patchDetailByID } = api_details;

  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");
  const deferredSearch = useDebounce(search, 500);
  const [disabled, setDisabled] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [error, setError] = useState("");

  const getData = (search) => {
    getProjects(search)
      .then((res) => setData(res.data))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!open) {
      setSelectedId(null);
    }
  }, [open]);

  useEffect(() => {
    if (setProjectId) {
      setProjectId(selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    setDisabled(!data || !selectedId || loading);
  }, [data, selectedId, loading]);

  useEffect(() => {
    if (!open) {
      setOpenConfirm(false);
    }
  }, [open]);

  const showNotification = (type, message) => {
    Store.addNotification({
      title: "Success",
      message,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  };

  const addModelsToProject = () => {
    checkedIds.forEach((id) => {
      let newArr = models.filter((el) => el.id != id);

      patchDetailByID({ project_id: selectedId }, id)
        .then((res) => {
          setModels([...newArr, res.data]);
          setForceUpdateModels(!forceUpdateModels);
          setUpdatedModels((prev) => [...prev, res.data]);

          setOppen(false);
          setSelectedId(null);
          setOpenConfirm(false);
          setCheckedIds([]);
          setRelated([]);
        })
        .catch((e) => {
          setError(e);
        });
    });

    if (!error) {
      showNotification("success", "Changed applied successfully");
    } else {
      showNotification("warning", "An error has occurred");
    }

    setError(null);
    setUpdatedModels([]);
  };

  useEffect(() => {
    getData(search);
  }, [deferredSearch]);

  return (
    <Modal
      title="Select a project"
      width={448}
      open={open}
      onCancel={() => setOppen(false)}
      closable={false}
    >
      <button
        onClick={() => setOppen(false)}
        style={{ background: "transparent" }}
        className="customModal_closingButtonContainer"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L20 20M2 20L20 2L2 20Z"
            stroke="#fff"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div className="input_wrapper">
        <Input
          className="input"
          placeholder="Find by project Name"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {data.length > 0 ? (
        <List className="project_list">
          {data?.map((project) => (
            <List.Item
              className="project_list_item"
              onClick={() => !openConfirm && setSelectedId(project.id)}
            >
              <span className="project_list_name">
                {ShortString(project.name, 45)}
              </span>
              <div
                style={{ cursor: !openConfirm ? "pointer" : "no-drop" }}
                className={`radio ${
                  project.id == selectedId ? "checked" : null
                }`}
              >
                <img className="check" src={check} alt="" />
              </div>
            </List.Item>
          ))}
        </List>
      ) : (
        <div className="flex_container">
          <p className="not-found">No projects found.</p>
          <Button type="primary" htmlType="button">
            <NavLink to="/admin/projects">Create</NavLink>
          </Button>
        </div>
      )}

      {related?.length > 0 ? (
        <CustomConfirm
          styles={{ minWidth: "450px", top: "-30px", right: "-25px" }}
          related={related}
          open={openConfirm}
          setOpen={setOpenConfirm}
          submit={addModelsToProject}
          nameLimit={47}
        >
          <Button
            style={{
              width: "100%",
              marginTop: 24,
            }}
            type="primary"
            disabled={disabled}
          >
            Save
          </Button>
        </CustomConfirm>
      ) : (
        <Button
          style={{
            width: "100%",
            marginTop: 24,
          }}
          type="primary"
          onClick={addModelsToProject}
          disabled={disabled}
        >
          Save
        </Button>
      )}
    </Modal>
  );
};

export default ConnectModelsToProject;
