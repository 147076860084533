import EditLocation from "components/ui/editLocation/EditLocation";
import { ApiUrl, instance } from "../apiEnv";

export const api_locations = {
  async getLocations() {
    let result = await instance.get(`${ApiUrl}/locations`);

    return result.data;
  },

  async getLocation(id) {
    let result = await instance.get(`${ApiUrl}/locations/${id}`);

    return result.data;
  },

  async addLocation(data) {
    let result = await instance.post(`${ApiUrl}/locations`, data);

    return result.data;
  },

  async removeLocation(id) {
    let result = await instance.delete(`${ApiUrl}/locations/${id}`);

    return result.data;
  },

  async editLocation({id, data}) {
    let result = await instance.put(`${ApiUrl}/locations/${id}`, data);

    return result.data;
  },
};
